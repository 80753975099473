<template>
    <topBanner/>
    <shipstoresupplier/>
</template>

<script>
import topBanner from '../components/common/topBanner.vue'
import shipstoresupplier from '../components/shipstoresupplier.vue'
export default {
    components: {
        topBanner,
        shipstoresupplier
    },
    data(){
        return{}
    }
}
</script>